exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-billiga-lan-tsx": () => import("./../../../src/pages/billiga-lan.tsx" /* webpackChunkName: "component---src-pages-billiga-lan-tsx" */),
  "component---src-pages-bolan-tsx": () => import("./../../../src/pages/bolan.tsx" /* webpackChunkName: "component---src-pages-bolan-tsx" */),
  "component---src-pages-budgetera-tsx": () => import("./../../../src/pages/budgetera.tsx" /* webpackChunkName: "component---src-pages-budgetera-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integritetspolicy-tsx": () => import("./../../../src/pages/integritetspolicy.tsx" /* webpackChunkName: "component---src-pages-integritetspolicy-tsx" */),
  "component---src-pages-kredit-kort-tsx": () => import("./../../../src/pages/kredit-kort.tsx" /* webpackChunkName: "component---src-pages-kredit-kort-tsx" */),
  "component---src-pages-lan-tsx": () => import("./../../../src/pages/lan.tsx" /* webpackChunkName: "component---src-pages-lan-tsx" */),
  "component---src-pages-lanekalkyl-tsx": () => import("./../../../src/pages/lanekalkyl.tsx" /* webpackChunkName: "component---src-pages-lanekalkyl-tsx" */),
  "component---src-pages-vad-ar-effektiv-ranta-tsx": () => import("./../../../src/pages/vad-ar-effektiv-ranta.tsx" /* webpackChunkName: "component---src-pages-vad-ar-effektiv-ranta-tsx" */)
}

